exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-applications-tsx": () => import("./../../../src/pages/applications.tsx" /* webpackChunkName: "component---src-pages-applications-tsx" */),
  "component---src-pages-consumables-parts-tsx": () => import("./../../../src/pages/consumables-parts.tsx" /* webpackChunkName: "component---src-pages-consumables-parts-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/categoryTemplate.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-product-template-tsx": () => import("./../../../src/templates/productTemplate.tsx" /* webpackChunkName: "component---src-templates-product-template-tsx" */)
}

